import React, {useState, useContext, version} from 'react'
import Row from 'react-materialize/lib/Row'
import Col from 'react-materialize/lib/Col'
import { FirebaseContext } from '../../firebase'
import { AuthContext } from '../../firebase'
import { StaticImage } from 'gatsby-plugin-image'
import {Image, Transformation, Placeholder} from 'cloudinary-react'
import Axios from 'axios'
import {BarLoader} from 'react-spinners'
import { css } from "@emotion/react";


const DoctorProfile = ({user, setUser}) => {

  const override = css`
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(0, -50%);
  `;
 
  

  const [loading, setLoading] = useState(false)
  
  const firebase = useContext(FirebaseContext)

  const [imgUpload, setImgUpload] = useState(false)

  const uploadImg = (e) =>{
    setLoading(true)
    const formData = new FormData()
    formData.append("file", imgUpload)
    formData.append("upload_preset", "wi2b0ha0")
    // formData.append("public_id", `docimg/Dr_${user.firstname}_${user.lastname}`)
    const cloudName = "bizhightech"

    Axios.post(
      `https://api.cloudinary.com/v1_1/${cloudName}/upload`,formData
    ).then((res) =>{
      firebase.db.collection('doctor').doc(user.uid).update({img: {public_id: res.data.public_id, url: res.data.secure_url}})
      .then(() =>{
        const updatedUser = {...user, img: {public_id: res.data.public_id, url: res.data.secure_url}}
        setUser(updatedUser)
        setLoading(false)
        setImgUpload(false)
      })     
    })
  }


  return (
    <div className="profile-bg">
      <Row className="profile-img-container">
        <Col l={3} m={4} s={12} className="center">
          {
            user.img
            ?
              <Image cloudName="bizhightech" publicId={user.img.public_id}>
                <Placeholder type="blur" />
                <Transformation width="180" height="180" gravity="faces" crop="fill" radius="max" />
                <Transformation quality="auto" fetchFormat="auto" />
              </Image>
              :
              <Image cloudName="bizhightech" publicId="docimg/allobluepng3_spb7o8">
                <Placeholder type="blur" />
                <Transformation width="180" height="180" gravity="faces" crop="fill" radius="max" />
                <Transformation quality="auto" fetchFormat="auto" />
              </Image>
            
              /* <StaticImage src="../../images/allocolor.png" alt="Allo Doc footer logo" height={180} layout="fixed" placeholder="none" className="doc-profile-img" /> */
            /* <StaticImage src="https://i.pravatar.cc/200" alt="Allo Doc footer logo" height={180} layout="fixed" placeholder="none" className="doc-profile-img" /> */
          }
        </Col>
        <Col l={9} m={8} s={12}>
          <h3 className="doctor-profile-name color-1">{user.isDoctor && <span>Dr  </span>}{`${user.firstname} ${user.lastname}`}{(user.title && (user.title !== "")) && <span>, {user.title}</span>}</h3>
          {
            user.img 
            ? 
              loading
              ?
                <div className="loader-container-1">
                  <BarLoader
                    css={override}
                    size={30}
                    color={"#2F80ED"}
                    loading={loading}
                  />
                </div>
              :
                <div className="profile-img-cta-contain">
                  <p className="color-1 profile-img-cta-txt">Vous voulez changer votre photo de profile?</p>
                  <div>
                    <input className="img-upload-input" type="file" accept="image/*" onChange={(e) => {setImgUpload(e.target.files[0])}}/>
                    {imgUpload && <button className="img-upload-btn" onClick={uploadImg} >Envoyer</button>}
                  </div>
                </div>
            :
              loading
                ?
                  <div className="loader-container-1">
                    <BarLoader
                      css={override}
                      size={30}
                      color={"#2F80ED"}
                      loading={loading}
                    />
                  </div>
                :
                  <div className="profile-img-cta-contain">
                    <p className="color-1 profile-img-cta-txt">
                      Veuillez ajouter votre photo de profile
                    </p>
                    <div>
                      <input className="img-upload-input" type="file" accept="image/*" onChange={(e) => {setImgUpload(e.target.files[0])}}/>
                      {imgUpload && <button className="img-upload-btn" onClick={uploadImg} >Envoyer</button>}
                    </div>
                  </div>
          }
        </Col>
      </Row>
      <Row>
        <Col l={6} m={6} s={12}>
          <Row>
            <Col l={4} m={12} s={12}>
              <span className="profile-item-title">Email: </span>
            </Col>
            <Col l={8} m={12} s={12}>
              <p className="profile-item-value"> { user.email || "..."}</p>
            </Col>
          </Row>
        </Col>
        <Col l={6} m={6} s={12}>
          <Row>
            <Col l={4} m={12} s={12}>
              <span className="profile-item-title">Phone: </span>
            </Col>
            <Col l={8} m={12} s={12}>
              <p className="profile-item-value"> {user.phone ? user.phone : "..."}</p>
            </Col>
          </Row>
        </Col>
        <Col l={6} m={6} s={12}>
          <Row>
            <Col l={4} m={12} s={12}>
              <span className="profile-item-title">Sp&eacute;cialit&eacute;: </span>
            </Col>
            <Col l={8} m={12} s={12}>
              <p className="profile-item-value"> {user.spec ? user.spec : "..."}</p>
            </Col>
          </Row>
        </Col>
        {/* <Col l={6} m={6} s={12}>
          <Row>
            <Col l={4} m={12} s={12}>
              <span className="profile-item-title">Titres: </span>
            </Col>
            <Col l={8} m={12} s={12}>
              <p className="profile-item-value"> {user.title ? user.title : "..."}</p>
            </Col>
          </Row>
        </Col> */}
        <Col l={6} m={6} s={12}>
          <Row>
            <Col l={4} m={12} s={12}>
              <span className="profile-item-title">Ann&eacute;e d'exp.: </span>
            </Col>
            <Col l={8} m={12} s={12}>
              <p className="profile-item-value"> {user.experience ? user.experience : "..."}</p>
            </Col>
          </Row>
        </Col>
        <Col l={6} m={6} s={12}>
          <Row>
            <Col l={4} m={12} s={12}>
              <span className="profile-item-title">Dur&eacute;e Consultation: </span>
            </Col>
            <Col l={8} m={12} s={12}>
              <p className="profile-item-value"> {user.duration ? user.duration : "..."}</p>
            </Col>
          </Row>
        </Col>
        <Col l={6} m={6} s={12}>
          <Row>
            <Col l={4} m={12} s={12}>
              <span className="profile-item-title">Prix Consultation: </span>
            </Col>
            <Col l={8} m={12} s={12}>
              <p className="profile-item-value"> {user.cost ? user.cost : "..."}</p>
            </Col>
          </Row>
        </Col>
        <Col l={12} m={12} s={12}>
          <Row>
            <Col l={2} m={12} s={12}>
              <span className="profile-item-title">R&eacute;sum&eacute;: </span>
            </Col>
            <Col l={10} m={12} s={12}>
              <p className="profile-item-value"> {user.summary ? user.summary : "..."}</p>
            </Col>
          </Row>
        </Col>
      </Row>
      {/* {
        user.isCompleted && <button className="villatalk">Modifier</button>
      } */}
      
    </div>
  )
}

export default DoctorProfile