import React, {useEffect} from 'react'
import { Router } from '@reach/router'
import Dashboard from '../app/dashboard'
// import Default from '../app/default'
import Profile from '../app/profile';
import Room from '../app/room';
import Return from '../app/return';
import Scheduler from '../app/scheduler';
import PrivateRoute from '../app/PrivateRoute'
import AppLayout from "../components/appLayout"
import Consultation from '../app/consultation';
import CCPayment from '../app/cCPayment';
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
// import { navigate } from 'gatsby-link';
// import Pdf from '../app/resources/pdf';


const stripePromise = loadStripe(`${process.env.GATSBY_STRIPE_PUBLIC_KEY}`);


const App = () => {

  useEffect(() => {

  }, [])
  
  return(
    <AppLayout>
    <Elements stripe={stripePromise}>
      <Router basepath="/app">
        <PrivateRoute path="/dashboard" component={Dashboard}/>
        <PrivateRoute path="/room" component={Room}/>
        <PrivateRoute path="/return" component={Return}/>
        <PrivateRoute path="/scheduler" component={Scheduler}/>
        <PrivateRoute path="/profile" component={Profile}/>
        <PrivateRoute path="/ccpayment" component={CCPayment}/>
        <PrivateRoute path="/consultations/:id" component={Consultation}/>
        {/* <PrivateRoute path="/exams/:userid" component={Pdf}/> */}
        {/* <Default path="/"/> */}
      </Router>
    </Elements>
    </AppLayout>
  )
}

export default App
