import React, {useState, useEffect} from 'react'
import Row from 'react-materialize/lib/Row'
import Col from 'react-materialize/lib/Col'
import AvailabilityModal from './availabilityModal'

const Availability = ({user, setUser}) => {
  const [modal, setModal] = useState(false)
  const [day, setDay] = useState(null)
  const showModal = (myDay) => {
    setDay(myDay)
    setModal(true)
  }

  const closeModal = () => {
    setModal(false)
  }

  const days =['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi']

  return (
    <>
      <div className="availability-container">
        <h4 className="color-1">Disponibilit&eacute;</h4>
        <div className="availability-card">
          <Row>
            {
              days.map( day =>{
                
                return(
                  <Col l={3} key={day}>
                    <div className="day color-0-bg center">
                      {/* <h5 className="color-3 center">Dimanche</h5> */}
                      <h5 className="color-3 center">{day}</h5>
                      <div className="day-avail color-3 center">
                      {
                        user.availability && user.availability[days.indexOf(day)]
                        ?
                          
                          user.availability[days.indexOf(day)].isAvailable
                          ?
                            user.availability[days.indexOf(day)].slots.map(slot =>{
                              return(
                                <p>{`${slot.start} - ${slot.end}`}</p>
                              )
                            })
                          :
                            <>
                              <p>Indisponible</p>
                            </>
                          
                        
                        :
                        <>
                          <p>Par d&eacute;faut</p>
                          {
                            (days.indexOf(day) == 0 || days.indexOf(day) == 6)
                            ?
                              <p>Indisponible</p>
                            :
                              <>
                                <p>7h am - 8h30 am</p>
                                <p>8h pm - 11h pm</p>
                              </>                          
                          }
                        </>
                      }
                      </div>
                      <button onClick={() => showModal(days.indexOf(day))} className="mybtn strong color-3-bg color-4">Modifier</button>
                    </div>
                  </Col>
                )                
              })
            }
          </Row>
        </div>
      </div>
      {modal && <AvailabilityModal user={user} setUser={setUser} day={day} visible={modal} close={closeModal} />}
    </>
  )
}

export default Availability