import React, {useState, useContext, forwardRef} from 'react';
import {Formik, Field, ErrorMessage} from 'formik'
import * as Yup from 'yup'
import { FirebaseContext } from '../../firebase'
import { StyledForm, StyledButton } from '../../UI/elements'
import Input from '../../UI/Form/input'
import {navigate} from 'gatsby'
import Col from 'react-materialize/lib/Col'
import Row from 'react-materialize/lib/Row'
// import {Row, Col} from 'react-materialize'
import Textarea from '../../UI/Form/textarea';
import { AuthContext } from '../../firebase'
// import SimpleMDE from "react-simplemde-editor";
// import "easymde/dist/easymde.min.css";


const CompleteSchema = Yup.object().shape({
  spec: Yup.string().required('Info obligatoire'),
  experience: Yup.string().required("Info obligatoire"),
  phone: Yup.string().required('Info obligatoire'),
  duration: Yup.number().required('Info obligatoire'),
  cost: Yup.number().required('Info obligatoire'),
  summary: Yup.string().required('Info obligatoire'),
  title: Yup.string(),
})



const CompleteDoctor = forwardRef((props, ref) => {
  const firebase = useContext(FirebaseContext)
  const {authUser, setAuthUser} = useContext(AuthContext)
  const {user} = authUser

  // const [docSummary, setDocSummary] = useState("")

  const updateUser = async (values) => {
    const userRef = firebase.db.collection("doctor").doc(props.user.uid)
    const {spec, experience, phone, duration, cost, summary, title} = values
    // let isCompleted
    // if(sex !== '' && age !== '' && phone !== '' && profession !== '' && religion !== '' && children !== ''){
    //   isCompleted = true
    // }else{
    //   isCompleted = false
    // }

    // if(title == ""){
    //   userRef.update({spec, experience, phone, duration, cost, summary, isCompleted: true
    //   })
    //   .then(() =>{
    //     const updatedUser = {...user, spec, experience, phone, duration, cost, summary, isCompleted: true}
    //     setAuthUser({user: updatedUser})
    //   })
    // }else{
      userRef.update({spec, experience, phone, duration, cost, summary, isCompleted: true, title})
      .then(() =>{
        const updatedUser = {...user, spec, experience, phone, duration, cost, summary, isCompleted: true, title}
        setAuthUser({user: updatedUser})
      })
    // }

    navigate('/app/profile')
    props.complete()
    props.close()
  }

  // console.log('docSummary :>> ', docSummary);

  return (
    <div ref={ref}>
      <Formik
        initialValues={{
          spec: props.user.spec || '',
          experience: props.user.experience || '',
          phone: props.user.phone || '',
          duration: props.user.duration || '',
          cost: props.user.cost || '',
          summary: props.user.summary || '',
          title: props.user.title || '',
          services: props.user.services || [],
        }}
        validationSchema={CompleteSchema}
        onSubmit={(values, actions) =>{
          // values.preventDefault()
          updateUser(values)

          actions.resetForm()
        }}
      >
        {({isSubmitting, isValid, handleReset}) => (
          <StyledForm>
            <Row className="margbot">
              <Col l={4} m={6} s={12}>
                <Field
                  type='text'
                  name='spec'
                  placeholder='Votre specialite ...'
                  component={Input}
                />
              </Col>
              <Col l={4} m={6} s={12}>
                <Field
                  type='number'
                  name='experience'
                  placeholder="Vos annees d'experience ..."
                  component={Input}
                />
              </Col>
              <Col l={4} m={6} s={12}>
                <Field
                  type='tel'
                  name='phone'
                  placeholder='Votre telephone ...'
                  component={Input}
                />
              </Col>
              <Col l={4} m={6} s={12}>
                <Field
                  type='text'
                  name='title'
                  placeholder='Vos titres ...'
                  component={Input}
                />
              </Col>
              <Col l={4} m={6} s={12}>
                <Field
                  type='number'
                  name='duration'
                  placeholder='La duree des consultations (en min) ...'
                  component={Input}
                />
              </Col>
              <Col l={4} m={6} s={12}>
                <Field
                  type='number'
                  name='cost'
                  placeholder='Le prix des consultations (en HTG) ...'
                  component={Input}
                />
              </Col>
              <Col l={12} m={12} s={12}>
                <Field
                  type='text'
                  name='summary'
                  placeholder="Le resume de vos competences ..."
                  component={Textarea}
                />
                {/* <SimpleMDE 
                  value={docSummary}
                  onChange={value => setDocSummary(value)}
               />
               <ErrorMessage name='summary' /> */}
              </Col>
              <Col l={12} m={12} s={12}>
               
              </Col>
            </Row>

            {props.close && handleReset}
            <button className="styled-button color-7-bg color-1" type='submit'>Envoyer</button>
          </StyledForm>           
        )}
      </Formik>
    
    </div>
  );
});

export default CompleteDoctor;