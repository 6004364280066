import React, {useState, useEffect, useContext} from 'react'
import {Formik, Field, FieldArray, Form, ErrorMessage} from 'formik'
import * as Yup from 'yup'
import { gsap } from 'gsap'
import  Col from 'react-materialize/lib/Col'
import Row from 'react-materialize/lib/Row'
import { FaTimes } from "react-icons/fa";
import Switch from 'react-materialize/lib/Switch'
import moment from 'moment'
import { FirebaseContext, AuthContext } from '../../firebase'
import {navigate} from 'gatsby'

const isValidSlot = (timeSlots) => {
  if (!timeSlots) return;

  // compare each slot to every other slot
  for (let i = 0; i < timeSlots.length; i++) {
    const slot1 = timeSlots[i];

    if (!slot1.start || !slot1.end) continue;

    const start1 = moment(slot1.start, "HH:mm");
    const end1 = moment(slot1.end, "HH:mm");

    for (let j = 0; j < timeSlots.length; j++) {
      // prevent comparision of slot with itself
      if (i === j) continue;

      const slot2 = timeSlots[j];

      if (!slot2.start || !slot2.end) continue;
      const start2 = moment(slot2.start, "HH:mm");
      const end2 = moment(slot2.end, "HH:mm");

      if (
        start2.isBetween(start1, end1, undefined, "[]") ||
        end2.isBetween(start1, end1, undefined, "[]")
      ) {
        return `le créneau ${j + 1} empiète sur un autre, veuillez corriger`;
      }
    }
  }
  // All time slots are are valid
  return "";
};

const AvailabilityModal = ({user, setUser, visible, close, day}) => {
  let modalVeil = null;
  let modalDialog = null;
  let modalContent = null;
  let modalHead = null

  const firebase = useContext(FirebaseContext)
  const {authUser, setAuthUser} = useContext(AuthContext)
  // const [userAvail, setUserAvail] = useState(user.availability)
  const defAvail = !(day == 0 || day == 6)
  const days =['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi']
  const [avail, setavail] = useState(
    user.availability && user.availability[day]
    ?
      user.availability[day].isAvailable
    :
      defAvail
  )

  const [modalTween] = useState(gsap.timeline({ paused: true }));


  useEffect(() => {
    modalTween
      .to(modalVeil, 0.25, { autoAlpha: 1 })
      .to(modalDialog, 0.35, { y: 0, autoAlpha: 1 })
      .from(modalHead, 0.35, {y: 15, opacity: 0, ease: "back"})
      .from(
        modalContent,
        0.35,
        { y: 15, opacity: 0, stagger: 0.1 },
        "-=0.15"
      )
      .from(
        "#close1", 
        0.35, 
        { y: 15, opacity: 0 }
      )
      .reverse();
  }, []);

  useEffect(() => {
    modalTween.reversed(!visible);
  }, []);

  const closeModal = () => {
    modalTween.reverse();
    gsap.delayedCall(modalTween.duration(), close);
  };

  const updateUser = async (values) =>{
    const userRef = firebase.db.collection(`doctor`).doc(user.uid)
    const {isAvailable, slots} = values

    let userAvail = {}
    if(user.availability){
      userAvail = {...user.availability, [day]: {isAvailable: avail, slots}}
    }else{
      userAvail[day] = {isAvailable: avail, slots}
    }

    userRef.update({availability: userAvail})
      .then(() =>{
        const updatedUser = {...user, availability: userAvail }
        setUser(updatedUser)
      })

    navigate('/app/profile')
    close()
  }

  const confirmAvail = async () =>{

    let userAvail = {}
    if(user.availability){
      userAvail = {...user.availability, [day]: {isAvailable: avail}}
    }else{
      userAvail[day] = {isAvailable: avail}
    }
    const userRef = firebase.db.collection("doctor").doc(user.uid)
    userRef.update({availability: userAvail})
      .then(() =>{
        const updatedUser = {...user, availability: userAvail }
        setUser(updatedUser)
      })

    navigate('/app/profile')
    // props.complete()
    close()
  }

  return (
    <div className={`modal-container ${visible ? " show" : ""}`}>
      <div className="modal-veil" role="button" tabIndex={0} ref={e => (modalVeil = e)} onClick={closeModal} onKeyDown={closeModal}>
        <FaTimes id="close1" className="close white-text" />
      </div>
      <div className="availability-dialog white" ref={e => (modalDialog = e)}>
        <h4 ref={e => (modalHead = e)} className="center color-1"> Disponibilit&eacute; pour {days[day]}</h4>
        <Formik
        initialValues={{ isAvailable: avail, slots: [{ start: "", end: "" }] }}
        onSubmit={updateUser}
        validationSchema={Yup.object().shape({
          isAvailable: Yup.boolean().required('Info obligatoire'),
          slots: Yup.array()
            .of(
              Yup.object().shape({
                start: Yup.string().required("Info obligatoire").test("startTest", "Heure non valide", function (
                  value
                ) {

                  const { from } = this
                  console.log('from', from)
                  const parent = from[0].value
                  console.log('parent', parent)
                  const slots = from[1].value.slots
                  console.log('slots', slots)
                  const index = slots.indexOf(parent)
                  console.log('index', index)
                  console.log('value', value)
                  if (!value) return true;
                  if(index){
                    if (
                      moment(value, "HH:mm").isSameOrBefore(
                        moment(slots[index -1].end, "HH:mm")
                      )
                    ) {
                      return this.createError({
                        message: "Le début du créneau doit être après la fin du précédent"
                      });
                    }
                  }
                  return moment(value, "HH:mm").isValid();
                }),
                end: Yup.string().required("Info obligatoire").test("endTest", "Heure non valide", function (
                  value
                ) {
                  if (!value) return true;
                  if (!moment(value, "HH:mm").isValid()) {
                    return this.createError({ message: "Heure non valide" });
                  }

                  if (
                    moment(this.parent.start, "HH:mm").isSameOrAfter(
                      moment(this.parent.end, "HH:mm")
                    )
                  ) {
                    return this.createError({
                      message: "La fin du créneau doit être après le début"
                    });
                  }

                  return true;
                })
              })
            )
            .test("timesTest", "Error", function (value) {
              const message = isValidSlot(value);
              return !message;
            })
        })}
        render={({ values, errors, handleReset}) => (
          <Form className="styledForm">
            <Row>
              <Col s={6}>
                <p className="color-0">Disponible ?:</p>
              </Col>
              <Col s={6}>
                <Switch
                  id="Switch-20"
                  offLabel="Non"
                  className="color-0 right text-sm"
                  onChange={() => setavail(!avail)}
                  onLabel="Oui"
                  checked={avail}
                />
              </Col>
            </Row>
            {
              avail &&
              <FieldArray
                name="slots"
                render={({push, remove}) => (
                  <div className="slot-contain">
                    {values.slots.map((time, index) => (
                      <div key={index}>
                        <Row>
                          <Col s={10}>
                            <h5 className="color-1 left">Cr&eacute;neau {index + 1}</h5>
                          </Col>
                          <Col s={2}>
                            <div className="remove-contain">
                              <div onClick={() => remove(index)} className="removeslot color-3  right">-</div>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col s={6}>
                            <label htmlFor="" className="color-0">
                              D&eacute;but
                            </label>
                            <Field
                              type="time"
                              className="styled-input2"
                              name={`slots.${index}.start`}
                            />
                            <div className="input-error2">
                              <ErrorMessage
                                name={`slots.${index}.start`}
                              />
                            </div>
                          </Col>
                          <Col s={6}>
                            <label htmlFor="" className="color-0">
                              Fin
                            </label>
                            <Field
                              type="time"
                              className="styled-input2"
                              name={`slots.${index}.end`}
                            />
                            <div className="input-error2">                              
                              <ErrorMessage
                                name={`slots.${index}.end`}
                              />
                            </div>
                          </Col>
                        </Row>
                      </div>
                    ))}
                    <div className="input-error2"> 
                    {isValidSlot(values.slots)}
                    </div>
                    {close && handleReset}
                    <div className="add-contain">
                      <div 
                        className="addslot color-3 right"
                        onClick={() => push({
                          start: '',
                          end: ''
                        }
                        )}
                      >
                        +
                      </div>
                    </div>
                    <div>
                      <button className="styled-button color-7-bg color-1 center" type="submit">
                        Envoyer
                      </button>
                    </div>
                  </div>
                )}
              />
            }
            {
              !avail &&
              <div>
              <button className="styled-button color-7-bg color-1 center" onClick={confirmAvail}>
                Confirmer 
              </button>
            </div>}
          </Form>
        )}
      />
      </div>
    </div>
  );
}

export default AvailabilityModal





// import * as Yup from "yup";
// import { Formik, Form, Field, FieldArray, ErrorMessage } from "formik";
// import moment from "moment";

// export default function App() {
//   const isValid = (timeSlots) => {
//     if (!timeSlots) return;

//     // compare each slot to every other slot
//     for (let i = 0; i < timeSlots.length; i++) {
//       const slot1 = timeSlots[i];

//       if (!slot1.start || !slot1.end) continue;

//       const start1 = moment(slot1.start, "HH:mm");
//       const end1 = moment(slot1.end, "HH:mm");

//       for (let j = 0; j < timeSlots.length; j++) {
//         // prevent comparision of slot with itself
//         if (i === j) continue;

//         const slot2 = timeSlots[j];

//         if (!slot2.start || !slot2.end) continue;
//         const start2 = moment(slot2.start, "HH:mm");
//         const end2 = moment(slot2.end, "HH:mm");

//         if (
//           start2.isBetween(start1, end1, undefined, "[]") ||
//           end2.isBetween(start1, end1, undefined, "[]")
//         ) {
//           return `Overlapping time in slot ${j + 1}`;
//         }
//       }
//     }
//     // All time slots are are valid
//     return "";
//   };

//   const handleSubmit = (values) => {
//     console.log(values.mondayTimes);
//   };

//   return (
//     <div className="container m-3">
//       <Formik
//         initialValues={{ mondayTimes: [{ start: "", end: "" }] }}
//         onSubmit={handleSubmit}
//         validationSchema={Yup.object().shape({
//           mondayTimes: Yup.array()
//             .of(
//               Yup.object().shape({
//                 start: Yup.string().test("startTest", "Invalid Time", function (
//                   value
//                 ) {
//                   if (!value) return true;
//                   return moment(value, "HH:mm").isValid();
//                 }),
//                 end: Yup.string().test("endTest", "Invalid Time", function (
//                   value
//                 ) {
//                   if (!value) return true;
//                   if (!moment(value, "HH:mm").isValid()) {
//                     return this.createError({ message: "Invalid Time" });
//                   }

//                   if (
//                     moment(this.parent.start, "HH:mm").isSameOrAfter(
//                       moment(this.parent.end, "HH:mm")
//                     )
//                   ) {
//                     return this.createError({
//                       message: "End time must be after start time"
//                     });
//                   }

//                   return true;
//                 })
//               })
//             )
//             .test("timesTest", "Error", function (value) {
//               const message = isValid(value);
//               return !message;
//             })
//         })}
//         render={({ values, errors }) => (
//           <Form>
//             <FieldArray
//               name="mondayTimes"
//               render={(arrayHelpers) => (
//                 <div className="">
//                   {values.mondayTimes.map((time, index) => (
//                     <div className="row" key={index}>
//                       <div className="col-5">
//                         <div className="mb-3">
//                           <label htmlFor="" className="form-label">
//                             Start
//                           </label>
//                           <Field
//                             type="time"
//                             className="form-control"
//                             name={`mondayTimes.${index}.start`}
//                           />
//                           <ErrorMessage
//                             className="form-text text-danger"
//                             name={`mondayTimes.${index}.start`}
//                           />
//                         </div>
//                       </div>
//                       <div className="col-5">
//                         <div className="mb-3">
//                           <label htmlFor="" className="form-label">
//                             End
//                           </label>
//                           <Field
//                             type="time"
//                             className="form-control"
//                             name={`mondayTimes.${index}.end`}
//                           />
//                           <ErrorMessage
//                             className="form-text text-danger"
//                             name={`mondayTimes.${index}.end`}
//                           />
//                         </div>
//                       </div>

//                       <div className="col-2 mt-4">
//                         <button
//                           className="btn btn-sm btn-danger m-2"
//                           type="button"
//                           onClick={() => arrayHelpers.remove(index)}
//                         >
//                           -
//                         </button>
//                       </div>
//                     </div>
//                   ))}

//                   {isValid(values.mondayTimes)}

//                   <button
//                     className="btn btn-sm btn-primary m-2"
//                     type="button"
//                     onClick={() =>
//                       arrayHelpers.insert(values.mondayTimes.length, {
//                         start: "",
//                         end: ""
//                       })
//                     }
//                   >
//                     +
//                   </button>
//                   <div>
//                     <button className="btn btn btn-primary m-2" type="submit">
//                       Submit
//                     </button>
//                   </div>
//                 </div>
//               )}
//             />
//           </Form>
//         )}
//       />
//     </div>
//   );
// }
