import React from 'react'
import ContentLoader from "react-content-loader"

const  DoctorLoader = (props) => (
  <div className="profile-bg">
    <ContentLoader 
      speed={2}
      width={800}
      height={560}
      viewBox="0 0 800 560"
      backgroundColor="#f9f2ff"
      foregroundColor="#eddbfd"
      {...props}
    >
      <rect x="277" y="44" rx="2" ry="2" width="222" height="20" /> 
      <rect x="114" y="238" rx="5" ry="5" width="260" height="40" /> 
      <circle cx="106" cy="101" r="90" /> 
      <rect x="114" y="292" rx="5" ry="5" width="260" height="40" /> 
      <rect x="485" y="238" rx="5" ry="5" width="260" height="40" /> 
      <rect x="487" y="292" rx="5" ry="5" width="260" height="40" /> 
      <rect x="115" y="346" rx="5" ry="5" width="260" height="40" /> 
      <rect x="487" y="346" rx="5" ry="5" width="260" height="40" /> 
      <rect x="114" y="401" rx="10" ry="10" width="637" height="100" />
    </ContentLoader>
  </div>
)
export default DoctorLoader