import React ,{useState, useContext, useEffect} from 'react'
import  Col from 'react-materialize/lib/Col'
import Row from 'react-materialize/lib/Row'
import { FirebaseContext } from '../firebase'
import { AuthContext } from '../firebase'
// import { gsap } from 'gsap'
import CompleteModal from './resources/completeModal'
import { navigate } from 'gatsby'
import PatientProfile from './resources/patientProfile'
import DoctorProfile from './resources/doctorProfile'
import DoctorLoader from './resources/doctorLoader'
import PatientLoader from './resources/patientLoader'
import Availability from './resources/availability'
import useProfile from './hooks/useProfile'
// import UpdatePwd from './resources/updatepwd'


const Profile = () => {

  const {authUser, setAuthUser} = useContext(AuthContext)
  const {user} = authUser

  const [loading, setLoading] = useState(true)

  
  const firebase = useContext(FirebaseContext)
  const [dbUser, setdbUser] = useState({})
  const [isCompleted, setIsCompleted] = useState(user.isCompleted)

  const [complete, setComplete] = useState(false)
  // const [isVerified, setIsVerified] = useState(user.emailVerified)
  // const [emailSent, setEmailSent] = useState(false)


  const showComplete = () => {
    setComplete(true)
  }

  const closeComplete = () => {
    setComplete(false)
  }

  const setIsComplete = () => {
    setIsCompleted(true)
  }


  // const deleteUser = () => {
  //   firebase.db.collection("user").doc(user.uid).delete().then(function(){
  //     console.log('delete :');
  //   })
  //   user.delete().then(function () {
  //     console.log('delete user fb :');
  //   })
  //   setAuthUser({user: ""})

  //   navigate("/")
  // }

  // const updatepwd = () => {
  //   // <UpdatePwd />
  // }

  useEffect(() => {
    getDBUser();
    // dbUser.age ? setIsCompleted(true) : setIsCompleted(false)
    // dbUser.age ? console.log('true :', true) : console.log('false', false)
  }, [])

  // const table = user.isDoctor ? "doctor" : "user"
  // const dbUser = useProfile(table, user.uid)
  // console.log('dbUser :>> ', dbUser);

  // user.isDoctor
  // ?
  //   dbUser = useProfile("doctor", user.uid)
  // :
  //   dbUser = useProfile("user", user.uid)

  const getDBUser = () => {
    user.isDoctor || user.isMedPro
    ?
    firebase.db.collection("doctor").doc(user.uid).get().then(function (doc){
      if(doc.exists){
        const myUser = {id: doc.id, ...doc.data()}
        setdbUser(myUser)
        setTimeout(() => {
          setLoading(false)
        }, 1000);
        
        // myUser.age ? setIsCompleted(true) : setIsCompleted(false)
      }
    }).catch(function(error) {
      console.log("Error updating user:", error);
    })

    :
    firebase.db.collection("user").doc(user.uid).get().then(function (doc){
      if(doc.exists){
        const myUser = {id: doc.id, ...doc.data()}
        setdbUser(myUser)
        setTimeout(() => {
          setLoading(false)
        }, 1000);
        
        // myUser.age ? setIsCompleted(true) : setIsCompleted(false)
      }
    }).catch(function(error) {
      console.log("Error updating user:", error);
    })
  }


  return (
    <div className="in-app">
      <Row>
        <Col l={4}>
          <h3 className="in-app-head-text color-4"> Profile</h3>
        </Col>
        <Col l={8}>
          {/* {
            isVerified ?
            ""
            :
            <div className="verify">
              <Row>
                <Col l={8}>
                  <p>Veuillez verifier votre email: </p>
                </Col>
                <Col l={4}>
                  <button onClick={handleVerification}>Verifier</button>
                </Col>
              </Row>
            </div>
          } */}
          {/* {
            emailSent 
            ?
            <div className="codesent">
              <Row>
                <p>Le code de verification a ete envoye dans votre boite aux lettres</p>
              </Row>
            </div>
            :
            ""
          } */}
          {
            user.isCompleted 
            ? 
              <div className="edit-profile">
                <Row>
                  <Col l={8}>
                    <p>Vous voulez modifier votre profil?</p>
                  </Col>
                  <Col l={4}>
                    <button onClick={showComplete}>Modifier</button>
                  </Col>
                </Row>
              </div>
            :
              <div className="incomplete">
                <Row>
                  <Col l={8}>
                    <p>Votre profile est incomplet, veuillez le completer: </p>
                  </Col>
                  <Col l={4}>
                    <button onClick={showComplete}>Completer</button>
                  </Col>
                </Row>
              </div>              
          }
        </Col>
      </Row>
      {
        loading
        ?
          user.isDoctor||user.isMedPro ? <DoctorLoader /> : <PatientLoader />
        :
          (user.isDoctor||user.isMedPro 
          ? 
          <>
            <DoctorProfile user={dbUser} setUser={setdbUser} /> 
            <Availability user={dbUser} setUser={setdbUser}  />
          </>
          : 
          <PatientProfile user={dbUser} />)

      }
      {complete && <CompleteModal complete={setIsComplete} user={dbUser} visible={complete} close={closeComplete} />}
      {/* <h4 className="in-app-subhead-text danger">Zone Dangereuse</h4>
      <div className="profile-bg-2">
        <Row className="update">
          <Col l={10}><p className="warning">Changer votre mot de passe</p></Col>
          <Col l={2}><button  onClick={deleteUser}>Changer</button></Col>
        </Row>
        <Row className="delete">
          <Col l={10}><p className="danger">Supprimer votre compte</p></Col>
          <Col l={2}><button  onClick={deleteUser}>Supprimer</button></Col>
        </Row>
      </div> */}
      {/* {user.isDoctor||user.isMedPro && } */}
    </div>
    
  )
}

export default Profile
