import React, { useState, useEffect } from "react";
import { gsap } from "gsap";
import { FaTimes } from "react-icons/fa";
import CompletePatient from './completePatient'
import CompleteDoctor from "./completeDoctor";



const CompleteModal = ({user, visible, close, complete}) => {
  let modalVeil = null;
  let modalDialog = null;
  let modalContent = null;
  let modalHead = null

  const [modalTween] = useState(gsap.timeline({ paused: true }));

  useEffect(() => {
    modalTween
      .to(modalVeil, 0.25, { autoAlpha: 1 })
      .to(modalDialog, 0.35, { y: 0, autoAlpha: 1 })
      .from(modalHead, 0.35, {y: 15, opacity: 0, ease: "back"})
      .from(
        modalContent,
        0.35,
        { y: 15, opacity: 0, stagger: 0.1 },
        "-=0.15"
      )
      .from(
        "#close1", 
        0.35, 
        { y: 15, opacity: 0 }
      )
      .reverse();
  }, [modalVeil, modalDialog, modalTween, modalHead, modalContent, visible]);

  useEffect(() => {
    modalTween.reversed(!visible);
  }, [visible, modalTween]);

  const closeModal = () => {
    modalTween.reverse();
    gsap.delayedCall(modalTween.duration(), close);
  };


  return (
    <div className={`modal-container ${visible ? " show" : ""}`}>
      <div className="modal-veil" role="button" tabIndex={0} ref={e => (modalVeil = e)} onClick={closeModal} onKeyDown={closeModal}>
        <FaTimes id="close1" className="close white-text" />
      </div>
      <div className="profile-dialog white" ref={e => (modalDialog = e)}>
        <h4 ref={e => (modalHead = e)} className="center color-1-text">Completez votre profil</h4>
        {
          (user.isDoctor || user.isMedPro)
          ?
          <CompleteDoctor complete={complete} user={user} ref={e => (modalContent = e)} close={closeModal} />
          :
          <CompletePatient complete={complete} user={user} ref={e => (modalContent = e)} close={closeModal} />   
        }
      </div>
    </div>
  );
};

export default CompleteModal;