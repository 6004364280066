import React, {useState, useEffect, useContext} from 'react';
import { navigate } from 'gatsby'
import { AuthContext } from '../../firebase'
import { FirebaseContext } from '../../firebase'

const PastBookingCard = ({booking}) => {

  const {authUser} = useContext(AuthContext)
  const {user} = authUser
  const firebase = useContext(FirebaseContext)

  const getAppointee = () => {

    firebase.db.collection((user.isDoctor || user.isMedPro) ? 'user' : 'doctor').doc((user.isDoctor || user.isMedPro) ? booking.patientId : booking.doctorId).get().then(doc =>{
      if(doc.exists){
        // setAppointee({firstName: doc.data().firstname, lastName: doc.data().lastname, spec: doc.data().spec})
        setAppointee({...doc.data()})
      }
    })
  }

  function formatDate(date) {
    
    const year = '' + date.getFullYear()
    const month = (date.getMonth() < 9 ? '0' : '') + (1 + date.getMonth())
    const day =  (date.getDate() < 10 ? '0' : '') +  date.getDate()
    const hour = (date.getHours() < 10 ? '0' : '') + date.getHours()
    const minute = (date.getMinutes()  < 10 ? '0' : '') + date.getMinutes()
    const secondes = (date.getSeconds()  < 10 ? '0' : '') + date.getSeconds()
    const myDate = year + '-' + month + '-' + day + '-' + hour + '-' + minute + '-' + secondes

    return myDate
  }

  function handleBooking(booking) {
    navigate(`/app/consultations/${booking.roomName}`, {state:{booking, appointee},})
  }

  const [appointee, setAppointee] = useState()

  useEffect(() => {
    getAppointee()
  }, [])

  console.log('appointee :>> ', appointee);


  return (
    <div className="past-list-item  color-3-bg">
      {appointee && <h4 className="color-4 appointee">
        <span>{appointee.isDoctor && "Dr "}</span>
        {`${appointee.firstname} ${appointee.lastname}`} 
        {(appointee.title && (appointee.title !== "")) && <span>, {appointee.title}</span>}
        </h4>
      }
      <p className="color-0">Date: <span>{formatDate(booking.startDate)}</span></p>
      <button onClick={() => handleBooking(booking)} className="mybtn color-1 color-7-bg">Voir</button>
    </div>
  );
};

export default PastBookingCard;