import React, {useContext, forwardRef} from 'react';
import {Formik, Field} from 'formik'
import * as Yup from 'yup'
import { FirebaseContext } from '../../firebase'
import { StyledForm, StyledButton } from '../../UI/elements'
import Input from '../../UI/Form/input'
import {navigate} from 'gatsby'
import Row from 'react-materialize/lib/Row'
import Col from 'react-materialize/lib/Col'
import { AuthContext } from '../../firebase'


const CompleteSchema = Yup.object().shape({
  sex: Yup.string().required('Veuillez entrer votre prenom'),
  age: Yup.string().required('Veuillez entrez votre nom'),
  phone: Yup.string().required('Veuillez entrer votre telephone'),
  profession: Yup.string().required('Veuillez entrer votre profession'),
  religion: Yup.string().required('Veuillez entrer votre religion'),
  children: Yup.string().required('Veuillez entrer le nombre d\'enfants'),
})



const CompletePatient = forwardRef((props, ref) => {
  const firebase = useContext(FirebaseContext)
  const {authUser, setAuthUser} = useContext(AuthContext)
  const {user} = authUser

  const updateUser = async (values) => {
    const userRef = firebase.db.collection("user").doc(props.user.uid)
    const {sex, age, phone, profession, religion, children} = values
    // let isCompleted
    // if(sex !== '' && age !== '' && phone !== '' && profession !== '' && religion !== '' && children !== ''){
    //   isCompleted = true
    // }else{
    //   isCompleted = false
    // }

    userRef.update({sex, age, phone, profession, religion, children, isCompleted: true})
    .then(() =>{
      const updatedUser = {...user, sex, age, phone, profession, religion, children, isCompleted: true}
      setAuthUser({user: updatedUser})
    })

    navigate("/app/profile")
    props.complete()
    props.close()
  }


  return (
    <div ref={ref}>
      <Formik
        initialValues={{
          sex: props.user.sex || '',
          age: props.user.age || '',
          phone: props.user.phone || '',
          profession: props.user.profession || '',
          religion: props.user.religion || '',
          children: props.user.children || ''
        }}
        validationSchema={CompleteSchema}
        onSubmit={(values, actions) =>{
          // values.preventDefault()
          updateUser(values)
          actions.resetForm()
        }}
      >
        {({isSubmitting, isValid, handleReset}) => (
          <StyledForm>
            <Row className="margbot">
              <Col l={6} m={6} s={12}>
                <Field
                  as='select'
                  name='sex'
                  className="styled-input"
                >
                  <option value="" disabled selected>Choisissez votre sexe</option>
                  <option value="male">Masculin</option>
                  <option value="female">F&eacute;minin</option>
                </Field>
              </Col>
              <Col l={6} m={6} s={12}>
                <Field
                  type='number'
                  name='age'
                  placeholder='Votre age ...'
                  component={Input}
                />
              </Col>
              <Col l={6} m={6} s={12}>
                <Field
                  type='tel'
                  name='phone'
                  placeholder='Votre phone ...'
                  component={Input}
                />
              </Col>
              <Col l={6} m={6} s={12}>
                <Field
                  type='text'
                  name='profession'
                  placeholder='Votre profession ...'
                  component={Input}
                />
              </Col>
              <Col l={6} m={6} s={12}>
                <Field
                  type='text'
                  name='religion'
                  placeholder='Votre religion ...'
                  component={Input}
                />
              </Col>
              <Col l={6} m={6} s={12}>
                <Field
                  type='number'
                  name='children'
                  placeholder="le nombre d'enfants ..."
                  component={Input}
                />
              </Col>
            </Row>

            {props.close && handleReset}
            <button className="styled-button color-7-bg color-1" type='submit'>Envoyer</button>
          </StyledForm>           
        )}
      </Formik>
    
    </div>
  );
});

export default CompletePatient;