import React, {useContext} from 'react';
import {Formik, Field} from 'formik'
import * as Yup from 'yup'
import Input from '../../UI/Form/input'
import Textarea from '../../UI/Form/textarea'
import Select from '../../UI/Form/select'
import {StyledForm, StyledButton} from '../../UI/elements'
import { FirebaseContext } from '../../firebase'

const FindingSchema = Yup.object().shape({
  finding: Yup.string(),
  exam: Yup.string(),
  medication: Yup.string()
})

const AddFindins = ({close, orderId}) => {
  const firebase = useContext(FirebaseContext)

  function updateOrder(values) {
    console.log('values :>> ', values)
    const {finding, exams, medication} = values
    firebase.db.collection('orders').doc(orderId).update({finding: finding, exams: exams, medication: medication})
  }
  let ref = null



  return (
    <div className="findings-form">
      <Formik
        initialValues={{
          finding: '',
          exams: '',
          medication: '',
        }}
        validationSchema={FindingSchema}
        onSubmit={(values, {setSubmitting}) =>{
        
          
          updateOrder(values)
          close()
        }}
      >
        {({isSubmitting, isValid, handleReset}) => (
          <StyledForm ref={el => el = ref}>
            <Field
              type='text'
              name='finding'
              placeholder='Trouvailles Cliniques ...'
              component={Textarea}
            />
            {/* <ErrorMessage name='email' /> */}
            <Field
              type='text'
              name='medication'
              placeholder='Prescription ...'
              component={Textarea}
            />
            <Field
              type='text'
              name='exams'
              placeholder='Examens de labo ...'
              component={Textarea}
            />
            {/* <Field 
              as="select" 
              name="exams"
              component={Select}
              placeholder='Categorie'
              multiple
            >
              <option value="" disabled>Categorie</option>
              <option value={3}>9 &agrave; 11 ans</option>
              <option value={2}>12 &agrave; 14 ans</option>
              <option value={1}>15 &agrave; 18 ans</option>
              <option value={0}>19 &agrave; 22 ans</option>
            </Field> */}
            {/* <ErrorMessage name='password' /> */}
            <button className="styled-button color-7-bg color-1" type='submit'>Envoyer</button>
            {/* {props.close && handleReset} */}
            {/* {close} */}
          </StyledForm>           
        )}
      </Formik>
    </div>
  );
};

export default AddFindins;