import React, {useContext, useState, useEffect} from 'react'
import { AuthContext } from '../firebase'
// import {Row, Col, CardPanel} from 'react-materialize'
import useQueryString from 'use-query-string';
import { FirebaseContext } from '../firebase'
import { google, outlook, office365, yahoo, ics } from "calendar-link";
import {formatRelative} from 'date-fns'
import { fr } from 'date-fns/locale'

const Return = (props) => {
  const {authUser} = useContext(AuthContext)
  const {user} = authUser
  const firebase = useContext(FirebaseContext)
  const [query, setQuery] = useQueryString(window.location)
  const [transaction, setTransaction] = useState(query.transactionId || null)
  const booking = JSON.parse(localStorage.getItem('booking')) || ""
  const doctorName = booking.doctorName

  // const eventPatient = {
  //   title: `Consultation en ligne avec ${doctorName}`,
  //   description: `Rendez-vous pour consultation en ligne avec ${doctorName}`,
  //   location: 'en ligne',
  //   start: new Date(booking.start),
  //   end: new Date(booking.end)
  // }

  // const googlePatient = google(eventPatient)
  // const outlookPatient = outlook(eventPatient)
  // const office365Patient = office365(eventPatient)
  // const yahooPatient = yahoo(eventPatient)


  const sendEmail = () =>{
    const booking = JSON.parse(localStorage.getItem('booking')) || ""

    const patientEmail = user.email    
    const doctorEmail = booking.doctorEmail
    const doctorName = booking.doctorName
    const startDate = formatRelative(new Date(booking.start), new Date(), { locale: fr })

    console.log(`booking`, booking)

    console.log('startDate :>> ', startDate);

    fetch('https://us-central1-allo-doc.cloudfunctions.net/sendEmail', {
      method: 'POST',
      body: JSON.stringify({patientName: `${user.firstname} ${user.lastname}`, patientEmail, doctorName, doctorEmail, startDate, price: booking.price, start: booking.start, end: booking.end})
    }).then(res => {
      return res.json();
    })

  }

  useEffect(() => {
    sendEmail()
  }, [])


  
  return (
    <div className="in-app">
      <h3 className="in-app-head-text color-4">Confirmation de paiment</h3>
      <div className="mycard vertical-center">
        {/* { 
          (transactionExist === null) && <p className="color-1-text greetings">...</p>
        }   */}

        {/* {
          (transactionExist === "not found") && <p className="color-1-text greetings">Transaction introuvable. Veuillez v&eacute;rifier que vous avez re&ccedil;u le message de confirmation sur votre t&eacute;l&eacute;phone. Sinon contactez Moncash.</p>
        }  */}

        {/* {
          (transactionExist === "found") && <UpdateBookings bookings={formattedBookings} orderId={orderId} userId={user.uid} sent={orderSent}/>
        }
        {
          (transactionExist === "in use") && <p className="color-1-text greetings">Le code de confirmation a d&eacute;j&agrave; &eacute;t&eacute; utilis&eacute;. Veuillez nous contacter pour plus de details.</p>
        } */}

          <p className="color-2">Votre paiement a &eacute;t&eacute; confirm&eacute;. Merci beaucoup. Vous recevrez un email les informations pour ajouter le rendez-vous a votre calendrier. </p>
          {/* <h6 className="color-4">Vous pouvez fermer cette page</h6> */}
          {/* <a href={googlePatient}>Google</a> */}
      </div>
     
    </div>
  )
}

export default Return
