import React, {useEffect, useMemo, useState, useContext} from 'react'
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement
} from "@stripe/react-stripe-js";
import { StaticImage } from 'gatsby-plugin-image'
import { FirebaseContext } from '../firebase'
import { navigate } from 'gatsby';


const useOptions = () => {
  // const fontSize = useResponsiveFontSize();
  const options = useMemo(
    () => ({
      style: {
        base: {
          // fontSize,
          iconColor: '#fff',
          color: "#fff",
          backgroundColor: '#2F80ED',
          height: '2em',
          letterSpacing: "0.025em",
          fontFamily: 'Poppins',
          "::placeholder": {
            color: "#ddd"
          }
        },
        invalid: {
          color: "#9e2146"
        }
      }
        // classes:{
        //   base: "payment-input"
        // }
    }),
    // [fontSize]
  );

  return options;
};


const CCPAyment = () => {
  const {doctor, orderId, user} = window.history.state
  const stripe = useStripe();
  const elements = useElements();
  const options = useOptions();
  const [paymentIntent, setPaymentIntent] = useState(null)
  const [error, setError] = useState(null)
  // const [transactionId, setTransactionId] = useQueryParam(orderId, StringParam);

  const confirmOrder = (pi) =>{
    firebase.db.collection('orders').doc(orderId).update({transactionId: pi, status: "completed"})
  }

  const firebase = useContext(FirebaseContext)

  useEffect(() => {
    getPaymentIntent()
  },[])
  
  const getPaymentIntent = () =>{
    const title = "Dr"
    const tailTitle = " " + doctor.title || ""
    const paymentData = {
      amount: Math.floor(doctor.cost*1.1*100),
      currency: 'htg',
      description: `Consultation en ligne avec ${doctor.isDoctor? title : "" } ${doctor.firstname} ${doctor.lastname}${tailTitle}`,
      metadata: {
        orderId
      },
      statement_descriptor_suffix: 'allodoc.app'
    }

    console.log('paymentData :>> ', paymentData);
    fetch('https://us-central1-allo-doc.cloudfunctions.net/getPaymentIntent', {
      method: 'POST',
      body: JSON.stringify(paymentData)
    })
    .then(res => {
      return res.json();
    }).then(data =>{
      const body = JSON.parse(data.body)
      setPaymentIntent(body.payment_intent)
    })
  }

  console.log('paymentIntent :>> ', paymentIntent);

  const handleSubmit = async event => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const result = await stripe.confirmCardPayment(paymentIntent.client_secret, {
      payment_method:  {
        card: elements.getElement(CardNumberElement),
        billing_details: {
          name: `${user.firstname} ${user.lastname}`,
        }
      },
      receipt_email: user.email
    });

    if (result.error) {
      // Show error to your customer (e.g., insufficient funds)
      setError(result.error.message)
      console.log(result.error.message);
    } else {
      // The payment has been processed!
      if (result.paymentIntent.status === 'succeeded') {
        confirmOrder(result.paymentIntent.id)
        //change order status to completed
        //redirect to confirmed payment page
        navigate(`/app/return?transactionId=${orderId}`)
        console.log('result :>> ', result);
      }
    }

    // console.log("[PaymentMethod]", payload);
  };

  return (
    <div className="in-app">
      <h4 className="in-app-head-text color-4"> Paiement par carte de cr&eacute;dit</h4>
      {/* <Elements stripe={stripePromise}> */}
      <div className="cc-payment-container">

      <div className="payment-form-container mycard3">
        {
          error
          ?
            <>
            <p className="color-1">{error}</p>
            <button className="mybtn color-3 color-0-bg" onClick={() => setError(null)}>R&eacute;essayer</button>
            </>
          :
          <form onSubmit={handleSubmit}>
            <label className="color-1">
              Num&eacute;ro de la carte
              <CardNumberElement
                options={options}
                onReady={() => {
                  console.log("CardNumberElement [ready]");
                }}
                onChange={event => {
                  console.log("CardNumberElement [change]", event);
                }}
                onBlur={() => {
                  console.log("CardNumberElement [blur]");
                }}
                onFocus={() => {
                  console.log("CardNumberElement [focus]");
                }}
              />
            </label>
            <label className="color-1">
            date d'expiration
              <CardExpiryElement
                options={options}
                onReady={() => {
                  console.log("CardNumberElement [ready]");
                }}
                onChange={event => {
                  console.log("CardNumberElement [change]", event);
                }}
                onBlur={() => {
                  console.log("CardNumberElement [blur]");
                }}
                onFocus={() => {
                  console.log("CardNumberElement [focus]");
                }}
              />
            </label>
            <label className="color-1">
              CVC
              <CardCvcElement
                options={options}
                onReady={() => {
                  console.log("CardNumberElement [ready]");
                }}
                onChange={event => {
                  console.log("CardNumberElement [change]", event);
                }}
                onBlur={() => {
                  console.log("CardNumberElement [blur]");
                }}
                onFocus={() => {
                  console.log("CardNumberElement [focus]");
                }}
              />
            </label>
            <div className="center">
              <button className="styled-button" type="submit" disabled={!stripe}>
                Payer {Math.floor(doctor.cost*1.1)} HTG
              </button>
            </div>
          </form>
        }
        <div className="powered-by-container">
          <a href="https://stripe.com/" className="stripe" target="_blank" rel="noopener noreferrer">
            <p>
            Propulsé par
            </p>
            <span>
            <StaticImage src="../images/stripe.svg" alt="Allo Doc footer logo" height={25} layout="fixed" placeholder="none" />
            </span>
            
          </a>
        </div>
      </div>
      </div>
      {/* </Elements> */}
    </div>
  )
}

export default CCPAyment