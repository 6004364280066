import React from 'react'
import ContentLoader from "react-content-loader"

const  PatientLoader = (props) => (
  <div className="profile-bg">
    <ContentLoader 
      speed={2}
      width={800}
      height={300}
      viewBox="0 0 800 300"
      backgroundColor="#f9f2ff"
      foregroundColor="#eddbfd"
      {...props}
    >
      <rect x="105" y="28" rx="5" ry="5" width="260" height="40" /> 
      <rect x="105" y="84" rx="5" ry="5" width="260" height="40" /> 
      <rect x="480" y="28" rx="5" ry="5" width="260" height="40" /> 
      <rect x="480" y="84" rx="5" ry="5" width="260" height="40" /> 
      <rect x="105" y="141" rx="5" ry="5" width="260" height="40" /> 
      <rect x="-217" y="502" rx="5" ry="5" width="260" height="40" /> 
      <rect x="480" y="141" rx="5" ry="5" width="260" height="40" /> 
      <rect x="105" y="197" rx="5" ry="5" width="260" height="40" /> 
      <rect x="480" y="197" rx="5" ry="5" width="260" height="40" /> 
      <rect x="105" y="254" rx="5" ry="5" width="260" height="40" />
    </ContentLoader>
  </div>
)
export default PatientLoader