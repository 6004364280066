import React, {useState, useEffect, useContext} from 'react'
import Video from './video/Video'
import Row from 'react-materialize/lib/Row'
import Col from 'react-materialize/lib/Col'
import { navigate } from 'gatsby'
import { FirebaseContext } from '../firebase'
import { AuthContext } from '../firebase'
import moment from 'moment'
import * as CONST from "./constants/constants"



const Room = () => {
  const {authUser} = useContext(AuthContext)
  const {user} = authUser
  const firebase = useContext(FirebaseContext)

  const [started, setStarted] = useState(false)


  const [userBookings, setUserBookings] = useState([])



  const handleSchedule = () => {
    navigate("/app/scheduler")
  }

  useEffect(() => {
    getBookings()
  }, [started])


  const today = Date.now() 
  
 
  const todayObj = new Date(today)
  const getBookings = () => {
    (user.isDoctor || user.isMedPro)
    ?
    firebase.db.collection("orders").where("booking.endDate", ">=", todayObj).where("status", "==", "completed").where("doctorId", "==", user.uid).onSnapshot(handleSnapshot)
    :
    firebase.db.collection("orders").where('userId', '==', user.uid).where('status', '==', 'completed').where('booking.endDate', '>=', todayObj).onSnapshot(handleSnapshot)
  }

  const [userInfo, setUserInfo] = useState({})

  const handleSnapshot = async (snapshot) => {
    let mybookings = []  

    snapshot.docs.map(doc =>{
        console.log('doc.data()', doc.data())
        if(moment(doc.data().booking.endDate.toDate()).isAfter(today)){
          const userId = (user.isDoctor || user.isMedPro) ? doc.data().userId: doc.data().doctorId
          mybookings.push({roomName: doc.id, startDate: doc.data().booking.startDate.toDate(), endDate: doc.data().booking.endDate.toDate(), userId})
        }
    })
    setUserBookings(mybookings)
  }

  const getUser = async (userType, userId) => {
   const userData = await firebase.db.collection(userType).doc(userId).get().then(doc => {
      if(doc.exists){
        return {firstname: doc.data().firstname, lastname: doc.data().lastname}
      }
    })
    setUserInfo({...userData})
  }

  console.log('userInfo :>> ', userInfo);

  console.log('userBookings :>> ', userBookings);

  return (
  <div className="in-app">
    <h3 className="in-app-head-text color-4">Salle de consultation</h3>
    {/* Ajouter condition pour render sois button sois welcome */}
    {
      userBookings.length 
      ?
      <p className="in-app-body-text">Bienvenue dans la salle de consultation. Vous pourrez cliquer sur le bouton du prochain rendez-vous quand l'heure sera venue.</p>
      :
      <div className="scheduleFirst color0">
        <Row>
          <Col l={9}>
            <p>{user.isDoctor ? "Nous n'avez pas de consultation programmée":"Prenez d'abord rendez-vous ici"}</p>
          </Col>
          {!user.isDoctor && <Col l={3}>
            <button onClick={handleSchedule}>Rendez-vous</button>
          </Col>}
        </Row>
      </div>
    }
    {userBookings.length ? <Video bookings={userBookings} started={started} setStarted={setStarted} /> : ""}
  </div>
  )
}

export default Room
