import React, {useState, useEffect, useContext, useLayoutEffect} from 'react'
import {
  AvailabilityCalendar
  // AvailabilityEvent,
  // MsSinceMidnightRange,
  // Booking,
  // Range,
  // CalendarThemeProp,
} from 'react-availability-calendar'
import { FirebaseContext } from '../firebase'
import { AuthContext } from '../firebase'
import * as CONST from "./constants/constants"
import CountUp from 'react-countup';
import {gsap} from 'gsap'
import moment from 'moment'
import Row from 'react-materialize/lib/Row'
import Col from 'react-materialize/lib/Col'
import {Formik, Field} from 'formik'
import * as Yup from 'yup'
import {Form} from 'formik'
import { navigate } from 'gatsby';
import Textarea from '../UI/Form/textarea'
import { StaticImage } from 'gatsby-plugin-image'
import { getNetTime } from '../utils/functions'





const MotifSchema = Yup.object().shape({
  motif: Yup.string('Motif insuffisant').required('Veuillez entrer votre motif de consultation'),
})

const msInHour = 60 * 60 * 1000
const msInMinute = 60 * 1000

const Scheduler = () => {

  const doctor = window.history.state.doctor


  const {authUser} = useContext(AuthContext)
  const {user} = authUser
  const firebase = useContext(FirebaseContext)
  const [bookings, setBookings] = useState([])
  const [books, setBooks] = useState([])
  const [selectedSlots, setSelectedSlots] = useState(0)
  const [paymentSent, setPaymentSent] = useState(false)
  const [motif, setMotif] = useState(null)
  const [orderId, setOrderId] = useState(null)


  useEffect(() => {
    if(doctor){
      getBookings()
    }
    
    // getPatientBookings()
  },[])


  // useEffect(() => {
  //   getNetTime()
  // }, [])

  useEffect(() => {
    let mounted = true
    getNetTime()
    .then(netTime =>{
      if(mounted){
        setNow(new Date(netTime.time_zone.current_time_unix*1000))
        // setNow(new Date(netTime.date_time))
      }
    })
    return () => {
      mounted = false
    }
  }, [now])


  const today = Date.now()
  const todayObj = new Date(today)
  const [now, setNow] = useState()


  const getBookings = async () => {
    if(doctor){
      // getNetTime()
      await firebase.db.collection("orders").where('doctorId', '==', doctor.uid).where('booking.startDate', '>=', todayObj).onSnapshot(handleSnapshot)
    }
  }
  const getPatientBookings = async () => {
    if(doctor){
      await firebase.db.collection("orders").where('patientId', '==', user.uid).where('booking.startDate', '>=', todayObj).onSnapshot(handlePatientSnapshot)
    }
  }


  const setBooking = (booking)  => {
    setBooks([...books, booking])
    setBookings([...bookings, booking])
    localStorage.setItem('bookings', JSON.stringify(books))
    setSelectedSlots(selectedSlots + 1)    
  }

  const handleSnapshot = (snapshot) => {
    let mybookings = []  
    snapshot.docs.map(doc =>{
        if((doc.data().status === "completed" )|| (doc.data().status === "pending")){

          mybookings.push({startDate: doc.data().booking.startDate.toDate(), endDate: doc.data().booking.endDate.toDate()})
        }

    })
    setBookings(mybookings)    
  }

  const handlePatientSnapshot = (snapshot) => {
    let mybookings = []  
    snapshot.docs.map(doc =>{
        if((doc.data().status === "completed" )|| (doc.data().status === "pending")){

          mybookings.push({startDate: doc.data().booking.startDate.toDate(), endDate: doc.data().booking.endDate.toDate()})
        }

    })
    setBookings([...bookings, ...mybookings])    
  }

  const createOrder = (id, startDate, endDate) => {
    firebase.db.collection("orders").doc(id).set({userId: user.uid, doctorId: doctor.uid, motif: motif, orderId: id, status: "pending", cost: doctor.cost, transactionDate: new Date(now), booking: {startDate: startDate, endDate: endDate}})

  }

  useEffect(() => {
    localStorage.setItem('bookings', JSON.stringify(books))
  }, [books])



  const dr = (doctor && doctor.isDoctor) ? "Dr " : ""

  const onAvailabilitySelected = (a) =>{

    const orderId = "APT" + "-" + dateFormat(now) + "-" + user.uid
    setOrderId(orderId)
    const mybook = {start: a.startDate, end: a.endDate, doctorName: doctor?`${dr} ${doctor.firstname} ${doctor.lastname}`: "", doctorEmail: doctor? `${doctor.email}`: "", price: doctor? doctor.cost : ""}
    // localStorage.setItem('orderId', JSON.stringify(orderId))
    localStorage.setItem('booking', JSON.stringify(mybook))
    const startDate = new Date(a.startDate)
    const endDate = new Date(a.endDate)
    setBooking({startDate, endDate})
    // console.log('startDate', startDate)
    createOrder(orderId, startDate, endDate)
  }
    
  const onChangedCalRange = (r) =>{

    console.log('Calendar range selected (fetch bookings here): ', r);
  }
 
  const [blockOutPeriods, setBlockOutPeriods] = useState(
    [
      [0 * msInHour, 7 * msInHour],
      [8.5 * msInHour, 20 * msInHour],
      [23 * msInHour, 24 * msInHour],
    ]
  )
  
  const onDaySelected = (d) =>{
    if(d){
      const day = d.getDay()
      let blockOut= []
      if(doctor.availability && doctor.availability[day]){
        if(doctor.availability[day].isAvailable){
          let time = 0
          let mySlot = [0* msInHour]
          const slots = doctor.availability[day].slots
          console.log('blockOut :>> ', blockOut);
          slots.forEach(slot =>{
            let [h , mn] = slot.start.split(":")
            console.log(`h`, h)
            console.log('mn :>> ', mn);
            time = parseInt(h)* msInHour + parseInt(mn) * msInMinute
            mySlot.push(time)
            // console.log('mySlot :>> ', mySlot);
            // console.log('blockOut :>> ', blockOut);
            blockOut.push(mySlot)

            let [h2 , mn2] = slot.end.split(":")
            console.log(`h`, h2)
            console.log('mn :>> ', mn2);
            mySlot = [parseInt(h2)* msInHour + parseInt(mn2) * msInMinute]
          })

          mySlot.push(23* msInHour + 59 * msInMinute)
          blockOut.push(mySlot)
          console.log('blockOut :>> ', blockOut);
          setBlockOutPeriods(blockOut)

        }else{
          blockOut = [
            [0* msInHour, 23* msInHour + 59 * msInMinute]
          ]
          setBlockOutPeriods(blockOut)
        }
      }else{
        if(day == 0 || day == 6){        
          blockOut = [
            [0* msInHour, 23* msInHour + 59 * msInMinute]
          ]
          setBlockOutPeriods(blockOut)
        }else{
          blockOut = [
            [0 * msInHour, 7 * msInHour],
            [8.5 * msInHour, 20 * msInHour],
            [23 * msInHour, 24 * msInHour],
          ]
          setBlockOutPeriods(blockOut)
        }
      }
      console.log(`day`, day)
    }
  }

  console.log(`blockOutPeriods`, blockOutPeriods)



  const slotLengthMs = (doctor ? doctor.duration : 0) * msInMinute
  const slotStepMs = (doctor ? doctor.duration : 0) * msInMinute 

  const theme={requestAppointmentLabel: "Choisissez la tranche horaire qui vous convient"}
 
  const providerTimeZone = 'America/New_York';

  const dateFormat = (date) => {
    const year = '' + date.getFullYear()
    const month = (date.getMonth() < 9 ? '0' : '') + (1 + date.getMonth())
    const day =  (date.getDate() < 10 ? '0' : '') +  date.getDate()
    const hour = (date.getHours() < 10 ? '0' : '') + date.getHours()
    const minute = (date.getMinutes()  < 10 ? '0' : '') + date.getMinutes()
    const secondes = (date.getSeconds()  < 10 ? '0' : '') + date.getSeconds()

    const myDate = year + month + day + hour + minute + secondes

    return myDate
  }

  const handlePayment = () => {
    setPaymentSent(true)
    let orderData = {
      amount : Math.floor(selectedSlots * doctor.cost*1.1),
      orderId: orderId
    }

    fetch('https://us-central1-allo-doc.cloudfunctions.net/moncashPayment', {
      method: 'POST',
      body: JSON.stringify(orderData)
    }).then(res => {
      return res.json();
    }).then(data =>{
      const body = JSON.parse(data.body)
      // window.open(body.paymentUrl, '_blank')
      window.open(body.paymentUrl, '_self', 'noopener')
    })
  }

  const handleCC = () =>{
    navigate('/app/ccpayment', {state:{doctor, orderId, user},})
  }

  const confirmPayment = () =>{
    navigate('/app/return')
  }

  useEffect(() => {
    selectedSlots && gsap.to(".payment-container", {autoAlpha: 1, display: 'block', y:-20, duration: 0.5, ease: "back"})
    // motif && gsap.to(".calendar", {opacity: 1, y:-20, duration: 0.5, ease: "back"})
    selectedSlots && gsap.to(".calendar", {opacity: 0, display: 'none', y:20, duration: 0.5, ease: "back"})
    selectedSlots && gsap.to(".payment-text", {opacity: 1, y:-20, duration: 0.5, ease: "back", delay: 0.2})
    selectedSlots && gsap.to(".payment-amount-container", {opacity: 1, y:-20, duration: 0.5, ease: "back", delay: 0.4})
    selectedSlots && gsap.to(".payment-btn-container", {opacity: 1, y:-20, duration: 0.5, ease: "back", delay: 0.5})
  }, [selectedSlots])

  useEffect(() => {
    motif && gsap.to(".calendar", {autoAlpha: 1, display: 'block', y:-20, duration: 0.5, ease: "back", delay: 0.5})
    !motif && gsap.to(".motif", {autoAlpha: 1, y:20, display: "block", duration: 1.5, ease: "back"})
    motif && gsap.to(".motif", {autoAlpha: 0, display: "none", y:-20, duration: 0.5, ease: "back"})

  }, [motif])


  const handleDoctor = () => {
    navigate("/doctors")
  }

  const handleMedPro = () => {
    navigate("/medpros")
  }

  // const dr = doctor && doctor.isDoctor && "Dr "

  return (
    <div className="in-app">
      {/* <Row>
        <Col l={12}> */}
          <h3 className="in-app-head-text color-4">Prendre rendez-vous</h3>
        {/* </Col>
      </Row> */}
      {
        doctor
        ?
        <>
          <Row>
            <Col l={12}>
              <p className="in-app-body-text color-1">Vous &ecirc;tes en train de prendre rendez-vous avec  {dr} <span>{`${doctor.firstname} ${doctor.lastname}`}</span>, <span className="scheduler-spec">{doctor.spec}</span>. La consultation dure <span>{doctor.duration} minutes</span> et co&ucirc;te <span>{Math.floor(doctor.cost*1.1)} {CONST.CURRENCY}</span>. Le paiement se fait par <span>Moncash</span> ou par <span>Carte de Cr&eacute;dit</span>. Assurez-vous d'avoir les fonds disponibles sur Moncash pour continuer le processus. Il vous reste 3 &eacute;tapes &agrave; compl&eacute;ter:</p>
            </Col>
          </Row>
          <div className="steps-contain">
            <Row >
              <Col l={4} m={4} s={12}><p className="steps">1- Mettre le motif de la consultation</p></Col>
              <Col l={5} m={4} s={12}><p className="steps">2- Choisir la date et la tranche d'heure</p></Col>
              <Col l={3} m={4} s={12}><p className="steps">3- Faire le paiement</p></Col>
            </Row>
          </div>
          <div className="motif">
            <h4 className="center color-0">Motif</h4>
            <Formik
              initialValues={{
                motif: '',
              }}
              validationSchema={MotifSchema}
              onSubmit={(values, {setSubmitting}) =>{
                getNetTime()
                setMotif(values.motif)
                localStorage.setItem("motif", JSON.stringify(values.motif))
              }}
            >
              {({isSubmitting, isValid}) => (
                <Form className="styled-form">
                  <Field
                    type='number'
                    name='motif'
                    placeholder='Le motif de votre rendez-vous'
                    component={Textarea}
                  />

                  <button className="styled-button" type='submit'>Envoyer</button>
                  
                </Form>           
              )}
            </Formik>          
          </div>
          <>
            <div className="calendar">
              <h5>Choisissez la date et l'heure</h5>
                {now && <AvailabilityCalendar
                  bookings={bookings}
                  theme={theme}
                  providerTimeZone={providerTimeZone}
                  moment={moment}
                  initialDate={now}
                  onAvailabilitySelected={onAvailabilitySelected}
                  onCalRangeChange={onChangedCalRange}
                  onDaySelected={onDaySelected}
                  blockOutPeriods={blockOutPeriods}
                  slotLengthMs={slotLengthMs}
                  slotStepMs={slotStepMs}
                  // excludeFn={d => d.getDay() === 0}
                />}
            </div>
            <div className="payment-container center">
            {
              paymentSent
              ?
              <>
                <p className="payment-sent color-2">
                  Merci <span>{user.firstname}</span>. Vous allez &ecirc;tre redirig&eacute; vers la plateforme Moncash pour effectuer le paiement. Une fois le processus termin&eacute; Moncash vous renverra ici. Si le processus de paiement s'est boucl&eacute; et vous n'&ecirc;tes pas revenu sur notre site, vueillez nous contacter sur <a href="mailto:info@villasantehaiti.com">info@allodoc.app</a>
                </p>
                {/* <Formik
                  initialValues={{
                    txId: '',
                  }}
                  validationSchema={PaymentSchema}
                  onSubmit={(values, {setSubmitting}) =>{
                    // setTxId(values.txId)
                    localStorage.setItem('txId', JSON.stringify(values.txId))
                    confirmPayment()
                    console.log('values', values)
                  }}
                >
                  {({isSubmitting, isValid}) => (
                    <StyledForm>
                      <Field
                        type='number'
                        name='txId'
                        placeholder='Id de transaction de Moncash ...'
                        component={Input}
                      />

                      <StyledButton className="color-0 color-1-text" type='submit'>Envoyer</StyledButton>
                      
                    </StyledForm>           
                  )}
                </Formik> */}
              </>
              :
              <>
                <p className="payment-text color-0">Votre totale est </p>
                <div className="payment-amount-container center">
                  <div className="payment-amount">
                    <h4>
                    <CountUp end={selectedSlots * doctor.cost*1.1} />
                    </h4>
                    <p>{CONST.CURRENCY}</p>
                  </div>          
                </div>
                <div className="payment-btn-container">
                  <button className="center btn-moncash" onClick={handlePayment} >
                    <StaticImage src="../images/moncash.png" alt="Moncash button" height={50} layout="fixed"  />
                  </button>
                  <button className="center mybtn color-2-bg color-3"  onClick={handleCC} >
                    Carte de Cr&eacute;dit
                  </button>
                </div>
              </>
            }
          </div>
          </>
        </>
        :
        <div className="select-doctor center">
          <p className="color-0">
            Pour prendre rendez-vous, veuillez cliquer ici <span role="img" aria-labelledby="point-down">👇</span> d'abord pour :
          </p>
          <button className="mybtn color-0-bg color-3" onClick={handleDoctor}>Choisir un M&eacute;decin</button>
          <h5>Ou</h5>
          <button className="mybtn color-0-bg color-3" onClick={handleMedPro}>Choisir un Professionel M&eacute;dical</button>
        </div>
      }
    </div>
  )
}

export default Scheduler
