import React, {useContext, useEffect, useRef, useState} from 'react'
import { navigate } from 'gatsby'
import '../styles/sidenav.css'
import { AuthContext } from '../firebase'
import { FirebaseContext } from '../firebase'
import { gsap } from 'gsap'
import {FaBars, FaTimes} from 'react-icons/fa'
import { isMobile } from 'react-device-detect'
import { StaticImage } from 'gatsby-plugin-image'

const Sidenav = () => {

  const [active, setActive] = useState(false)

  const { authUser, setAuthUser }  = useContext(AuthContext)
  const {user} = authUser
  let headText = useRef(null)
  let items = useRef(null)
  let sidenav = useRef(null)

  useEffect(() => {
    // gsap.from(headText, {opacity: 0, duration: 1.8, y: -20, ease: "back", delay: .6})
    gsap.from(items.children, {opacity: 0, duration: .8, x: -20, ease: "back", delay: .5, stagger: .1})
    // gsap.from(".mysidenav", {opacity: 0, duration: .8, x: -20, ease: "power.Out", delay: .2})

    isMobile && active && gsap.to(".navcontrol", {x:200, duration: 0.5})
    isMobile && !active && gsap.to(".navcontrol", {x:0, duration: 0.5, delay:.2})
    active && gsap.to(".mysidenav", {x:220, duration: 0.5, delay:.2})
    !active && gsap.to(".mysidenav", {x:0, duration: 0.5})

  }, [headText, items, active])

  // const { user } = authUser
  const Firebase = useContext(FirebaseContext)

  const logoutUser = async () =>{
    navigate("/")
    await Firebase.logout()
    setAuthUser({user: ""})
    localStorage.removeItem('user')
  }

  function changeNav(){
    isMobile && setActive(!active)
  }

  function handleDashboard() {
    navigate('/app/dashboard')
    isMobile && setActive(!active)
  }

  function handleScheduler() {
    navigate('/app/scheduler')
    isMobile && setActive(!active)
  }
  function handleRoom() {
    navigate('/app/room')
    isMobile && setActive(!active)
  }
  function handleProfile() {
    navigate('/app/profile')
    isMobile && setActive(!active)
  }



  return(
    <div>
      {isMobile && <button 
        className= "circle white navcontrol"
        onClick={changeNav}
      >
        {!active?<FaBars className="navopen color-1-text" />:<FaTimes className="navopen color-1-text" />}
      </button>}
      <div>
        <div className="mysidenav color-0-bg color-3">
          <div className="sidenav-head" onClick={() => handleDashboard()}>
            {/* <Image mystyle="sidenav-head-logo" /> */}
            <StaticImage src="../images/allowhite.png" alt="Allo Doc footer logo" height={150} layout="fixed" placeholder="none" className="" />
            {/* <h3 ref={el => headText = el} className="sidenav-head-logo-text color-0-text">Allô doc</h3>                    */}
          </div>
          <div ref={ el => items = el} className="sidenav-item-list">
            {!user.isDoctor && !user.isMedPro && <div className="sidenav-item">
              <div onClick={() => handleScheduler()} className="color-1-text">Prendre Rendez-vous </div>
            </div>}
            <div className="sidenav-item">
              <div onClick={() => handleRoom()}className="color-1-text">Salle de Consultation</div>        
            </div>
            <div className="sidenav-item">
              <div onClick={() => handleProfile()} className="color-1-text">Votre Profil</div>
            </div>
            <div className="sidenav-item">
              <div className="color-1-text" onClick={() => logoutUser()} >D&eacute;connexion</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
  
}

export default Sidenav
