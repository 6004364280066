import React from 'react';
import FutureBookingCard from './futureBookingCard';

const Futurebookings = ({bookings}) => {

  function formatDate(date) {
    
    const year = '' + date.getFullYear()
    const month = (date.getMonth() < 9 ? '0' : '') + (1 + date.getMonth())
    const day =  (date.getDate() < 10 ? '0' : '') +  date.getDate()
    const hour = (date.getHours() < 10 ? '0' : '') + date.getHours()
    const minute = (date.getMinutes()  < 10 ? '0' : '') + date.getMinutes()
    const secondes = (date.getSeconds()  < 10 ? '0' : '') + date.getSeconds()

    const myDate = year + '-' + month + '-' + day + '-' + hour + '-' + minute + '-' + secondes

    return myDate
  
  }

  return (
    <div className="future color-7-bg">
      <h6 className="future-title color-1">Consultations Futures</h6>
      <div className="future-list">
        {
          bookings.map( booking =>{
            return(
              <FutureBookingCard booking={booking} />
            )
          })
        }
      </div>
    </div>
  );
};

export default Futurebookings;