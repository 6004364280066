import React, {useContext, useRef} from 'react';
import Row from 'react-materialize/lib/Row';
import Col from 'react-materialize/lib/Col';
import { navigate } from 'gatsby';
import { AuthContext } from '../firebase'
import { useReactToPrint } from 'react-to-print';


const Consultation = () => {
  const {booking, appointee} = window.history.state
  const {authUser} = useContext(AuthContext)
  const {user} = authUser



  function formatDate(date) {
    
    const year = '' + date.getFullYear()
    const month = (date.getMonth() < 9 ? '0' : '') + (1 + date.getMonth())
    const day =  (date.getDate() < 10 ? '0' : '') +  date.getDate()
    // const hour = (date.getHours() < 10 ? '0' : '') + date.getHours()
    // const minute = (date.getMinutes()  < 10 ? '0' : '') + date.getMinutes()
    // const secondes = (date.getSeconds()  < 10 ? '0' : '') + date.getSeconds()
    const myDate = day + '-' + month + '-' + year 
    // + '-' + hour + '-' + minute + '-' + secondes

    return myDate
  
  }

  function handleClick(info) {
    navigate(`/app/exams/${user.uid}`, {state:{info},})
  }

  const componentRef = useRef();
  const componentRef2 = useRef();

  const handlePrintExams = useReactToPrint({
    content: () => componentRef.current,
  });
  const handleMedication = useReactToPrint({
    content: () => componentRef2.current,
  });


  return (
    <div className="in-app">
      <div className="consultation">
        {/* <p className="color-2 greetings">Hello <span>{user.fullName}</span>,</p> */}
        <div className="consultation-title center">
          <p className="color-4">{appointee.isDoctor && "Dr "}<span>{`${appointee.firstname} ${appointee.lastname}`}</span> {(appointee.title && (appointee.title !== "")) && `, ${appointee.title}`}</p>
        </div>
        <div className="consultation-body">
          <p className="color-1"><span>Date: </span>{formatDate(booking.startDate)}</p>
          <p className="consultation-subhead color-1">Motif de Consultation:</p>
          <p className="consultation-summary color-1">{booking.motif}</p>
          {(user.isDoctor || user.isMedPro) && <p className="consultation-subhead color-1">Trouvailles Clinique:</p>}
          {(user.isDoctor || user.isMedPro) && <p className="consultation-summary color-1">{booking.finding}</p>}
          <div className="exams">
            <p className="consultation-subhead color-1">Examens:</p>
            <Row>
              <Col s={9}>
                <p className="consultation-summary color-1">{booking.exams}</p> 
                <div style={{ display: "none" }}>
                  <div className="print-container color-7" ref={componentRef}>
                    <div className="print-header">
                      <img src="https://res.cloudinary.com/bizhightech/image/upload/v1628203300/docimg/allocolor_wf69xq.png" alt="Allo Doc logo" height="100" className="print-header-logo" />
                      <div className="print-header-dr-container color-2 center">
                        <h3>{appointee.isDoctor&&<span>Dr </span>}{`${appointee.firstname} ${appointee.lastname}`}{(appointee.title && (appointee.title !== "")) && <span>, {appointee.title}</span>}</h3>
                        <h4>{appointee.spec}</h4>
                        <p>Examens</p>
                      </div>
                    </div>
                    <div className="divider color-2-bg"></div>
                    &nbsp;
                    <p className="print-date color-2"><span>Date: </span>{formatDate(booking.startDate)}</p>
                    <p className="print-patient color-2"><span>Patient: </span>{`${user.firstname} ${user.lastname}`}</p>

                    <p className="print-exams color-2"><span>Examens: </span>{booking.exams}</p>

                    <div className="print-footer center">
                    <div className="divider"></div>
                      <p className="color-2">https://allodoc.app/</p>
                    </div>
                  </div>
                </div>             
              </Col>
              <Col s={3}>
                {!user.isDoctor && !user.isMedPro && <button className="mybtn color-1 color-7-bg" onClick={handlePrintExams}>
                  T&eacute;l&eacute;charger
                </button>}
              </Col>
            </Row>
          </div>
          <p className="consultation-subhead color-1">Prescriptions:</p>
          <Row>
            <Col s={9}>
              <p className="consultation-summary color-1">{booking.medication}</p> 
              <div style={{ display: "none" }}>
                  <div className="print-container color-7" ref={componentRef2}>
                    <div className="print-header">
                      <img src="https://res.cloudinary.com/bizhightech/image/upload/v1628203300/docimg/allocolor_wf69xq.png" alt="Allo Doc logo" height="100" className="print-header-logo" />
                      <div className="print-header-dr-container color-2 center">
                        <h3>{appointee.isDoctor && <span>Dr </span>}{`${appointee.firstname} ${appointee.lastname}`} {(appointee.title && (appointee.title !== "")) && <span>, {appointee.title}</span>}</h3>
                        <h4>{appointee.spec}</h4>
                        <p>Examens</p>
                      </div>
                    </div>
                    <div className="divider color-2-bg"></div>
                    &nbsp;
                    <p className="print-date color-2"><span>Date: </span>{formatDate(booking.startDate)}</p>
                    <p className="print-patient color-2"><span>Patient: </span>{`${user.firstname} ${user.lastname}`}</p>

                    <p className="print-exams color-2"><span>Prescription: </span>{booking.medication}</p>

                    <div className="print-footer center">
                    <div className="divider"></div>
                      <p className="color-2">https://allodoc.app/</p>
                    </div>
                  </div>
                </div>             
            </Col>
            <Col s={3}>
              {!user.isDoctor && !user.isMedPro && <button className="mybtn color-1 color-7-bg" onClick={handleMedication}>
                T&eacute;l&eacute;charger
              </button>}
            </Col>
          </Row>
        </div>
        
      </div>
    </div>
  );
};

export default Consultation;