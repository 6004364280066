import React, {useContext, useEffect, useState} from 'react'
import { AuthContext } from '../firebase'
import { FirebaseContext } from '../firebase'
import moment from 'moment'
import * as CONST from "./constants/constants"
// import {Row, Col, CardPanel} from 'react-materialize'
import Appointments from './resources/appointments'
import Row from 'react-materialize/lib/Row'
import Col from 'react-materialize/lib/Col'
import { navigate } from 'gatsby'
import Futurebookings from './resources/futurebookings'
import PastBookingCard from './resources/pastBookingCard'

const Dashboard = () => {
  const {authUser} = useContext(AuthContext)
  const {user} = authUser
  const firebase = useContext(FirebaseContext)
  
  const [pastBookings, setPastBookings] = useState([])
  const [futureBookings, setFutureBookings] = useState([])
  const [patient, setPatient] = useState([])
  const [cost, setCost] = useState(0)


  useEffect(() => {
    getBookings()
  }, [])

  const today = Date.now() 
  const todayObj = new Date(today)
  const getBookings = () => {
    (user.isDoctor || user.isMedPro)
    ?
    firebase.db.collection("orders").where('doctorId', '==', user.uid).where("status", "==", "completed").orderBy("booking.startDate", "desc").onSnapshot(handleSnapshot)
    :
    firebase.db.collection("orders").where('userId', '==', user.uid).where('status', '==', 'completed').orderBy("booking.startDate", "desc").onSnapshot(handleSnapshot)
  }

  const handleSnapshot = (snapshot) => {
    let future = []  
    let past = []  
    let patients = []
    let cost = 0

    console.log('snap :>> ');

    snapshot.docs.map(doc =>{
      if(moment(doc.data().booking.endDate.toDate()).isAfter(today)){
        future.push({roomName: doc.id, startDate: doc.data().booking.startDate.toDate(), endDate: doc.data().booking.endDate.toDate(), patientId: doc.data().userId, doctorId: doc.data().doctorId})
      }
      else {
        past.push({roomName: doc.id, startDate: doc.data().booking.startDate.toDate(), endDate: doc.data().booking.endDate.toDate(), motif: doc.data().motif, finding: doc.data().finding, exams: doc.data().exams, medication: doc.data().medication, motif: doc.data().motif , patientId: doc.data().userId, doctorId: doc.data().doctorId})

        patients.push(doc.data().userId)
        cost += doc.data().cost
      }
    })
    setPastBookings(past)
    setFutureBookings(future)
    setPatient(patients)
    setCost(cost)
  }


  function handleSchedule(){
    navigate('/app/scheduler')
  }

  function handleRoom(){
    navigate('/app/room')
  }

  function formatDate(date) {
    
    const year = '' + date.getFullYear()
    const month = (date.getMonth() < 9 ? '0' : '') + (1 + date.getMonth())
    const day =  (date.getDate() < 10 ? '0' : '') +  date.getDate()
    const hour = (date.getHours() < 10 ? '0' : '') + date.getHours()
    const minute = (date.getMinutes()  < 10 ? '0' : '') + date.getMinutes()
    const secondes = (date.getSeconds()  < 10 ? '0' : '') + date.getSeconds()
    const myDate = year + '-' + month + '-' + day + '-' + hour + '-' + minute + '-' + secondes
    return myDate  
  }

  function handleBooking(booking) {
    navigate(`/app/consultations/${booking.roomName}`, {state:{booking},})
  }

  function unique (arr){
    const filteredArray = arr.filter(function(item, pos){
      return arr.indexOf(item)== pos; 
    });
    return filteredArray.length
  }

  return (
    <div className="in-app">
      <p className="color-4 greetings">Hello {user.isDoctor && "Dr"} <span>{(user.isDoctor || user.isMedPro) ? `${user.firstname} ${user.lastname}` : user.firstname}</span>{(user.title && (user.title !== "")) && `, ${user.title}`},</p>
      <div className="dashboard">
        {/* <div className="mycard dash-pos">
          <Row>
            <Col className="center" l={6}>
              <button className="dash-cta color-1 white-text" onClick={handleSchedule}>Prendre Rendez-vous</button>
            </Col>
            <Col className="center" l={6}>
              <button className="dash-cta color-0 color-1-text" onClick={handleRoom}>Salle de Consultation</button>
            </Col>
          </Row>
        </div> */}
        { user.isDoctor && user.isCompleted && <Row>
          <Col l={4} m={6} s={12}>
            <div className="mycard2 center color-1-bg color-3 kpi">
                <h6>Consultations</h6>
                <h2>{`${patient.length}`}</h2>
            </div>
          </Col>
          <Col l={4} m={6} s={12}>
            <div className="mycard2 center color-4-bg color-3 kpi">
                <h6>Patients</h6>
                <h2>{`${unique(patient)}`}</h2>
            </div>
          </Col>
          <Col l={4} m={6} s={12}>
            <div className="mycard2 center color-2-bg color-3 kpi">
                <h6>Gains</h6>
                <h2>{`${cost*0.9}`}</h2>
            </div>
          </Col>
        </Row>}
        <div className="past-bookings">
          <h6 className="past-bookings-head color-1">Consultations pass&eacute;es</h6>
          <Row>
            {
              pastBookings.map(booking => {
                return(
                  <Col l={4} m={6} s={12} key={booking.roomName}>
                    <PastBookingCard booking={booking} />
                  </Col>
                )
              })
            }
          </Row>
        </div>
        <div className="hide-on-med-and-down">
          <Futurebookings bookings={futureBookings} />
        </div>
      </div>
  </div>
  )
}

export default Dashboard
