import React, {useState, useContext} from 'react'
import Row from 'react-materialize/lib/Row'
import Col from 'react-materialize/lib/Col'
import { FirebaseContext } from '../../firebase'


const PatientProfile = ({user}) => {
  
  return (
    <div className="profile-bg">
      <Row>
        <Col l={6} m={6} s={12}>
          <Row>
            <Col l={4} m={12} s={12}>
              <span className="profile-item-title">Nom: </span>
            </Col>
            <Col l={8} m={12} s={12}>
              <p className="profile-item-value bold"> &nbsp; {user.firstname ? user.firstname : "..."}</p>
            </Col>
          </Row>
        </Col>
        <Col l={6} m={6} s={12}>
          <Row>
            <Col l={4} m={12} s={12}>
              <span className="profile-item-title">Pr&eacute;nom: </span>
            </Col>
            <Col l={8} m={12} s={12}>
              <p className="profile-item-value bold"> &nbsp; {user.lastname ? user.lastname : "..."}</p>
            </Col>
          </Row>
        </Col>
        <Col l={6} m={6} s={12}>
          <Row>
            <Col l={4} m={12} s={12}>
              <span className="profile-item-title">Email: </span>
            </Col>
            <Col l={8} m={12} s={12}>
              <p className="profile-item-value"> &nbsp; {user.email ? user.email : "..."}</p>
            </Col>
          </Row>
        </Col>
        <Col l={6} m={6} s={12}>
          <Row>
            <Col l={4} m={12} s={12}>
              <span className="profile-item-title">Phone: </span>
            </Col>
            <Col l={8} m={12} s={12}>
              <p className="profile-item-value"> &nbsp; {user.phone ? user.phone : "..."}</p>
            </Col>
          </Row>
        </Col>
        <Col l={6} m={6} s={12}>
          <Row>
            <Col l={4} m={12} s={12}>
              <span className="profile-item-title">Sex: </span>
            </Col>
            <Col l={8} m={12} s={12}>
              <p className="profile-item-value"> &nbsp; {user.sex ? user.sex : "..."}</p>
            </Col>
          </Row>
        </Col>
        <Col l={6} m={6} s={12}>
          <Row>
            <Col l={4} m={12} s={12}>
              <span className="profile-item-title">Age: </span>
            </Col>
            <Col l={8} m={12} s={12}>
              <p className="profile-item-value"> &nbsp; {user.age ? user.age : "..."}</p>
            </Col>
          </Row>
        </Col>
        <Col l={6} m={6} s={12}>
          <Row>
            <Col l={4} m={12} s={12}>
              <span className="profile-item-title">Profession: </span>
            </Col>
            <Col l={8} m={12} s={12}>
              <p className="profile-item-value"> &nbsp; {user.profession ? user.profession : "..."}</p>
            </Col>
          </Row>
        </Col>
        <Col l={6} m={6} s={12}>
          <Row>
            <Col l={4} m={12} s={12}>
              <span className="profile-item-title">Nb d'enfants: </span>
            </Col>
            <Col l={8} m={12} s={12}>
              <p className="profile-item-value"> &nbsp; {user.children ? user.children : "..."}</p>
            </Col>
          </Row>
        </Col>
        <Col l={6} m={6} s={12}>
          <Row>
            <Col l={4} m={12} s={12}>
              <span className="profile-item-title">Religion: </span>
            </Col>
            <Col l={8} m={12} s={12}>
              <p className="profile-item-value"> &nbsp; {user.religion ? user.religion : "..."}</p>
            </Col>
          </Row>
        </Col>
      </Row>
      {/* <button className="villatalk">Modifier</button> */}
    </div>
  )
}

export default PatientProfile