import React, {useState, useEffect, useContext} from 'react';
import { navigate } from 'gatsby'
import { AuthContext } from '../../firebase'
import { FirebaseContext } from '../../firebase'

const FutureBookingCard = ({booking}) => {

  const {authUser} = useContext(AuthContext)
  const {user} = authUser
  const firebase = useContext(FirebaseContext)

  const getAppointee = () => {

    firebase.db.collection((user.isDoctor || user.isMedPro) ? 'user' : 'doctor').doc((user.isDoctor || user.isMedPro) ? booking.patientId : booking.doctorId).get().then(doc =>{
      if(doc.exists){
        setAppointee({...doc.data()})
      }
    })
  }

  function formatDate(date) {
    
    const year = '' + date.getFullYear()
    const month = (date.getMonth() < 9 ? '0' : '') + (1 + date.getMonth())
    const day =  (date.getDate() < 10 ? '0' : '') +  date.getDate()
    const hour = (date.getHours() < 10 ? '0' : '') + date.getHours()
    const minute = (date.getMinutes()  < 10 ? '0' : '') + date.getMinutes()
    // const secondes = (date.getSeconds()  < 10 ? '0' : '') + date.getSeconds()
    const myDate = year + '-' + month + '-' + day + ' ' + hour + 'h ' + minute + 'mn'
    //  + secondes

    return myDate
  }

  function handleBooking(booking) {
    navigate(`/app/consultations/${booking.roomName}`, {state:{booking, appointee},})
  }

  const [appointee, setAppointee] = useState()

  useEffect(() => {
    getAppointee()
  }, [])

  console.log('appointee :>> ', appointee);


  return (
    <div className="future-list-item">
      {appointee && <p><span>{appointee.isDoctor && "Dr "}</span>{`${appointee.firstname} ${appointee.lastname}`} {(appointee.title && (appointee.title !== "")) && <span>, {appointee.title}</span>}</p>}
      <p>D&eacute;but: <span>{formatDate(booking.startDate)}</span></p>
      {/* <p>Fin: <span>{formatDate(booking.endDate)}</span></p> */}
    </div>
  );
};

export default FutureBookingCard;