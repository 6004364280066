export const PRICE = 2500
// export const PRICE = 10
export const CURRENCY = "HTG"
export const SLOT_LENGTH = 20
export const DOCTOR1 = "u3TIGUrHqKMXI7daYr4rjgEFYM13"
export const DOCTOR = "SoLGykR6zjcaw2OHy4atZXFv0YR2"
export const ERRORS = {
  'auth/wrong-password': 'Votre mot de passe est incorrect',
  'auth/user-not-found': "Nous n'avons pas trouvé d'utilisateur correspondant à cet email",
  'auth/invalid-email': "L'adresse email n'est pas valide",
  'auth/user-disabled': "L'utilisateur a été désactivé",
  'auth/email-already-in-use': "L'adresse email est déjà en utilisation",
  'auth/weak-password': "Le mot de passe est trop faible",
  'auth/network-request-failed': "Une erreur de réseau s'est produite, veuillez verifier votre connection",
  'auth/code-not-found': "Votre code est introuvable",
  'auth/wrong-path-1': "Veuillez vous connecter sur la page des medecins",
}
