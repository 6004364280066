import React, {useContext, useCallback, useState, useEffect} from 'react'
import {AuthContext } from '../../firebase'
import axios from 'axios'
import Room from './Room'
import Col from 'react-materialize/lib/Col'
import moment from 'moment-timezone'
import Timer from 'react-compound-timer'
import {FaPhone} from 'react-icons/fa';
import { FirebaseContext } from '../../firebase'
import { navigate } from 'gatsby'
import { getNetTime } from '../../utils/functions'


const Appointment = ({booking}) => {
  const firebase = useContext(FirebaseContext)

  const [bookingInfo, setBookingInfo] = useState({})

  const getUser = async (userId) => {
    (user.isDoctor || user.isMedPro)
    ?
      await firebase.db.collection('user').doc(userId).get().then(doc =>{
        if(doc.exists){
          setBookingInfo({...booking, firstname: doc.data().firstname, lastname: doc.data().lastname})
        }
      })
    :
      await firebase.db.collection('doctor').doc(userId).get().then(doc =>{
        if(doc.exists){
          setBookingInfo({...booking, ...doc.data()})
        }
      })
  }

  useEffect(() => {
    getUser(booking.userId)
    // getNetTime()
  }, [])

  const [now, setNow] = useState()

  useEffect(() => {
    let mounted = true
    getNetTime()
    .then(netTime =>{
      if(mounted){
        console.log(`netTime`, netTime)
        setNow(new Date(netTime.time_zone.current_time_unix*1000))
        // setNow(new Date(netTime.date_time))
      }
    })
    return () => {
      mounted = false
    }
  }, [])



  console.log('bookingInfo :>> ', booking);
  // const now = new Date()
  // const now = moment().tz('America/Resolute')
  console.log('now :>> ', now);
  // .setZone('America/Los_Angeles')
  const [isDisabled, setIsDisabled] = useState(moment(now).isBetween(booking.startDate, booking.endDate))
  const { authUser } = useContext(AuthContext)
  const { user } = authUser
  const [token, setToken] = useState(null)

  const handleClick = async () => {
    const result = await axios({
      method: 'POST',
      url: 'https://timberwolf-lynx-1724.twil.io/create-token',
      data:{
        identity: user.firstname
      }
    })
    setToken(result.data)
  }

  console.log('isDisabled :>> ', isDisabled);


  const roomName = booking.roomName

  const handleLogout = useCallback(event => {
    setToken(null);
    user.isDoctor ? navigate('/app/dashboard'): navigate('/app/dashboard')
  }, [])


  const handleTimerStart = () => {
    setIsDisabled(!isDisabled)
  }


  return (
    <Col l={4} m={4} s={12}>
      {
        !token 
        ? 
        <div className="appointment mycard2  color-3-bg">
          <p className="color-0 ">Rendez-vous avec</p>
          <h3 className="color-4">{bookingInfo.isDoctor && "Dr "}<strong>{`${bookingInfo.firstname} ${bookingInfo.lastname}`}</strong>{(bookingInfo.title && (bookingInfo.title !== "")) && `, ${bookingInfo.title}`}</h3>

            {
              !isDisabled
              ?
              now && <>
                <p className="color-0">dans:</p>
                <Timer
                  initialTime={moment(booking.startDate).diff(now)}
                  direction="backward"
                  checkpoints={[
                    {
                        time: 0,
                        callback: () => {
                          handleTimerStart()
                        },
                    },
                  ]}
                >
                  <>
                    <h5 className="timer color-2">
                      <Timer.Days /> <span>J </span>
                      <Timer.Hours /> <span> H </span>
                      <Timer.Minutes /> <span>Mn </span>
                      <Timer.Seconds /> <span>Sec</span>
                    </h5>            
                  </>
                </Timer>
              </>
              :
              <>
                <p className="color-0">Cliquez ici pour lancer l'appel.</p>
                <div className="circle call-btn" onClick={handleClick}>
                  <FaPhone className="reverse color-3" />
                </div>
              </>
            } 
            {/* </>         */}
          {/* } */}
        </div>
        : 
        <Room roomName={roomName} endTime={booking.endDate} token={token} handleLogout={handleLogout} />
      
      
      }
    </Col>
  );
};

export default Appointment;